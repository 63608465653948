import React, { useState } from "react";
import styles from "./FoundationReportsLeftNav.module.scss";
import {
  SanityFoundationReport,
  SanityFoundationReportMeta,
  SanityFoundationReportsPage
} from "../../model/impact";
import { urlForDocument } from "../../urls";
import Link from "../Link/Link";
import groupBy from "lodash.groupby";
import sortBy from "lodash.sortby";
import Text from "../Text/Text";
import PlainButton from "../Button/PlainButton/PlainButton";

interface FoundationReportsLeftNavProps {
  active: SanityFoundationReport | SanityFoundationReportsPage;
  introduction: SanityFoundationReportsPage;
  entries: SanityFoundationReportMeta[];
}

const FoundationReportsLeftNav = ({
  active: initialActive,
  introduction,
  entries
}: FoundationReportsLeftNavProps) => {
  const [active, setActive] = useState<string | undefined>(
    initialActive._type === "foundationReport"
      ? (initialActive as SanityFoundationReport).location.name
      : undefined
  );
  const toggleActive = (clicked: string) => {
    setActive(clicked === active ? undefined : clicked);
  };
  const byLocation = groupBy(entries, article => article.location.name);
  const locationsInOrder = locationSort(Object.keys(byLocation));
  return (
    <ul className={styles.root}>
      <li key={introduction._id}>
        <Link
          className={
            introduction._id === initialActive._id
              ? styles.activeLink
              : styles.inactiveLink
          }
          to={urlForDocument(introduction)}
        >
          <Text variant="defaultBold">{introduction.title}</Text>
        </Link>
      </li>

      {locationsInOrder.map(location => (
        <li key={location}>
          <PlainButton
            onClick={() => {
              toggleActive(location);
            }}
            style={{ padding: 0 }}
          >
            <Text variant="defaultBold">{location}</Text>
          </PlainButton>
          {location === active && (
            <ul className={styles.secondLevel}>
              {byLocation[location].map(article => (
                <li key={article._id}>
                  <Link
                    className={
                      article._id === initialActive._id
                        ? styles.activeLink
                        : styles.inactiveLink
                    }
                    to={urlForDocument(article)}
                  >
                    <Text variant="defaultLight">{article.title}</Text>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

const locationSort = (values: string[]) =>
  // National bias.
  sortBy(values, v => (v === "United Kingdom" ? "\0" : v));

export default FoundationReportsLeftNav;
