import React from "react";
import TwoColumnLayout from "../../layouts/TwoColumnLayout/TwoColumnLayout";
import {
  SanityFoundationReport,
  SanityFoundationReportMeta,
  SanityFoundationReportsPage
} from "../../model/impact";
import TitleRow from "../../components/TitleRow/TitleRow";
import ArticleContent from "../../components/ArticleContent/ArticleContent";
import FoundationReportsLeftNav from "../../components/FoundationReportsLeftNav/FoundationReportsLeftNav";
import { useString } from "../../i18n/GetText";
import { SanityCampaign } from "../../model/common";

export interface FoundationReportsLayoutProps {
  campaigns: SanityCampaign[];
  allMeta: SanityFoundationReportMeta[];
  article: SanityFoundationReport | SanityFoundationReportsPage;
  introduction: SanityFoundationReportsPage;
}

export const FoundationReportsLayout = ({
  campaigns,
  article,
  allMeta,
  introduction
}: FoundationReportsLayoutProps) => {
  const h1 = useString("foundation-reports");
  return (
    <TwoColumnLayout
      campaigns={campaigns}
      topRows={<TitleRow title={h1} socialTitle={article.title} />}
      aside={
        <nav>
          <FoundationReportsLeftNav
            introduction={introduction}
            active={article}
            entries={allMeta}
          />
        </nav>
      }
      main={
        <ArticleContent
          article={article}
          related={
            article._type === "foundationReport"
              ? (article as SanityFoundationReport).related
              : []
          }
          relatedTitleId="related-stories"
        />
      }
    />
  );
};

export default FoundationReportsLayout;
