import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import FoundationReportsLayout from "../layouts/FoundationReportsLayout/FoundationReportsLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import {
  SanityFoundationReport,
  SanityFoundationReportMeta,
  SanityFoundationReportsPage
} from "../model/impact";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query FoundationReport($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityFoundationReport(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      location {
        ... on SanityCountry {
          name
        }
        ... on SanityRegion {
          name
        }
      }
      type
      related {
        _id
        _type
        title
        location {
          ... on SanityCountry {
            name
          }
          ... on SanityRegion {
            name
          }
        }
        type
        slug {
          current
        }
        image {
          ...SanityImage
        }
      }
      metaDescription
      _rawContent(resolveReferences: { maxDepth: 5 })
    }
    introduction: allSanityFoundationReportsPage {
      nodes {
        _id
        _type
        title
      }
    }
    allMeta: allSanityFoundationReport(sort: { fields: [type, title] }) {
      nodes {
        _id
        _type
        title
        location {
          ... on SanityCountry {
            name
          }
          ... on SanityRegion {
            name
          }
        }
        type
        slug {
          current
        }
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "impact" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const FoundationReport = ({
  pageContext,
  data: { menus, page, campaigns, allMeta, introduction },
  location
}: FoundationReportProps) => {
  const { title } = page;
  return (
    <PageLayout
      siteArea={SiteArea.IMPACT}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates,
        type: "article"
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <FoundationReportsLayout
        introduction={introduction.nodes[0]}
        article={page}
        allMeta={allMeta.nodes}
        campaigns={campaigns.nodes}
      />
    </PageLayout>
  );
};

export default FoundationReport;

interface FoundationReportProps {
  data: {
    menus: GlobalMenus;
    page: SanityFoundationReport;
    campaigns: { nodes: SanityCampaign[] };
    allMeta: { nodes: SanityFoundationReportMeta[] };
    introduction: { nodes: SanityFoundationReportsPage[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
